<template>
<div class="frontPage">
  <div class="mainHeader">
    <img class="img" src="@/assets/imgs/frontPage/page1.jpg">
    <div class="header">
      <div class="title">
        <img class="logo" src="@/assets/imgs/frontPage/logo.png">
      </div>
      <div class="list">
        <div class="item" @click="goHome">租赁商城</div>
        <div class="item">企业采购</div>
        <div class="item">设备维保</div>
        <div class="item">资产管理咨询</div>
        <div class="item">
          <router-link to="/enterpriseAssistance">企业融资</router-link>
        </div>
        <div class="item">
          设备回收
        </div>
      </div>
      <div class="login" @click="goLogin">登录 / 注册</div>
    </div>
<!--    <div class="learn" @click="goHome">了解更多</div>-->
  </div>
  <div class="centerMain">
    <div class="item">
      <img class="img" src="@/assets/imgs/frontPage/page2.jpg">
    </div>
    <div  class="item">
      <img class="img" src="@/assets/imgs/frontPage/page3.jpg">
    </div>
    <div  class="item">
      <img class="img" src="@/assets/imgs/frontPage/page4.png">
    </div>
  </div>
</div>
</template>
<script>
export  default {
  name:'frontPage',
  methods:{
    goHome(){
      this.$router.push({path:'/home'})
    },
    goLogin(){
      this.$router.push({path:'/Login'})
    }
  }
}
</script>

<style scoped lang="scss">
.frontPage{
  .mainHeader{
    width: 100%;
    //height: 1265px;
    position: relative;
    .img{
      width: 100%;
      //height: 100%;
      position: absolute;
      img{
        width: 100%;
        //height: 100%;
      }
    }
    .header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      color: #1b1f23;
      width: 80%;
      position: absolute;
      top: -15px;
      left: 10%;
      .title{
        .logo{
          height: 100px;
        }
      }
      .list{
        width: 800px;
        display: flex;
        align-items: center;
        justify-content:space-between ;
        .item{
          cursor: pointer;
          a{
            color: #1b1f23 !important;
          }
        }
      }
      .login{
        cursor: pointer;
      }
    }
    .learn{
      font-size: 24px;
      padding: 10px 50px;
      background-color: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(10px);
      border-radius: 10px;
      width: 150px;
      text-align: center;
      position: absolute;
      bottom: 50%;
      left: 45%;
      cursor: pointer;
    }
  }
  .centerMain{
    .item{
      width: 100%;
      //height: 800px;
      margin-bottom: 20px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}

</style>